import React from "react";

import "../../../../../style/css/index.css";

import {Col, Container, Row} from "react-bootstrap";

import ChangeItem from "./ChangeItem";

import {Fade} from "react-reveal";

const ChangeBox = (property) => {

    return (
        <Fade bottom cascade distance={"125px"} duration={950}>
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={"6"} sm={"12"}>
                        <div className={"changelogbox-container"}>
                            <Container>
                                <Row className="justify-content-md-center">
                                    <Col sm={"12"}>
                                        <span
                                            className={"changelogbox-index"}>CHANGELOG v<span>{property.value["version"]}</span></span>
                                        <hr className={"text-color-gray"}/>
                                    </Col>

                                    {property.value["add"].length != 0 ?
                                        <ChangeItem array={property.value["add"]} type={"add"}/> : null}
                                    {property.value["remove"].length != 0 ?
                                        <ChangeItem array={property.value["remove"]} type={"remove"}/> : null}
                                    {property.value["change"].length != 0 ?
                                        <ChangeItem array={property.value["change"]} type={"change"}/> : null}

                                    <span className={"text-color-dark"}>{property.value["data"]}</span>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Fade>
    );
};

export default ChangeBox;