import React from "react";
import {Col, Container, Row} from "react-bootstrap";

import Modal from 'react-awesome-modal';

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import 'animate.css/animate.min.css';

import MethodPayment from "./module/MethodPayment";
import ButtonPayment from "./module/ButtonPayment";
import DescriptionPayment from "./module/DescriptionPayment";
import ContainerPayment from "./module/ContainerPayment";
import InputPayment from "./module/InputPayment";
import axios from "axios";
import {url_api} from "../../../config";

import {store} from 'react-notifications-component';

class ShopItem extends React.Component {
    constructor(property) {
        super(property);

        //PaymentType
        // 0 - blik
        // 1 - psc
        // 2 - paypal
        // 3 - DirectBilling

        this.state = {
            visible: false,
            paymentType: 0,
            lastName: "",
            email: "",
            checkbox: false,
            id: 0
        }

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.changeButton = this.changeButton.bind(this);
        this.changeDays = this.changeDays.bind(this);
        this.createPayments = this.createPayments.bind(this);
    }

    openModal() {
        this.setState({
            visible: true
        });
    }

    closeModal() {
        this.setState({
            visible: false
        });
    }

    changeButton(id) {
        this.setState({
            paymentType: id
        });
    }

    changeDays(id) {
        let idChange;
        switch (id) {
            case 20:
                idChange = 0;
                break;
            case 60:
                idChange = 1;
                break;
            case 100:
                idChange = 2;
                break;
        }

        this.setState({
            id: idChange
        });
    }

    getName(id) {
        switch (id) {
            case 0:
                return "Przelew Online";
            case 1:
                return "PaysafeCard"
            case 2:
                return "PayPal"
            case 3:
                return "Direct Billing"
        }
    }

    async createPayments() {
        if ((this.state.paymentType == 0 && (!this.state.email.toString().includes("@")) || this.state.email.toString().slice(this.state.email.length - 1) == "@") || (this.state.paymentType == 0 && (this.state.email.length == 0 || this.state.lastName.length == 0 || !this.state.checkbox)) || (this.state.paymentType != 0 && (this.state.lastName.length == 0 || !this.state.checkbox))) {
            store.addNotification({
                title: "Dane",
                message: "Formularz nie został poprawnie wypełniony!",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__bounceIn"],
                animationOut: ["animate__bounceOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
            return;
        }

        const response = await axios.post(url_api + this.state.paymentType + "/payment", {
            id: this.props.isRang ? this.props.value.offer[this.state.id].id : this.props.value.offer.id,
            lastName: this.state.lastName,
            email: this.state.email
        });

        const json = response.data;
        if (json.status == "url") {
            store.addNotification({
                title: "Przekierowywanie!",
                message: "Trwa przekierowanie do płatności!",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__bounceIn"],
                animationOut: ["animate__bounceOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });

            window.location.href = json.url.toString();
        } else {
            console.log(json.description)
            store.addNotification({
                title: "Błąd!",
                message: json.description,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__bounceIn"],
                animationOut: ["animate__bounceOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }
    };

    render() {
        return (
            <Col md={"auto"}>
                <ContainerPayment main={this} image={this.props.value.image}
                                  price={this.props.isRang ? this.props.value.offer[0].price[0] : this.props.value.offer.price[0]} name={this.props.value.name}/>
                <Modal visible={this.state.visible} width="700" height="570" effect="fadeInUp"
                       onClickAway={() => this.closeModal()}>
                    <div className={"shopModule-container"}>
                        <Container>
                            <Row>
                                <MethodPayment main={this}/>
                                <Col md={4} sm={4} xs={4} className={"margin-auto"}>
                                    <h2 className={"shopModule-title-2"}>{this.props.value.name}</h2>
                                    {/*TODO zmiana na rangi stałe*/}
                                    {/*{this.props.isRang ?*/}
                                    {/*    <div>*/}
                                    {/*        <span>Wybierz na ile dni chcesz zakupic range</span>*/}
                                    {/*        <div className={"shopModule-slider"}>*/}
                                    {/*            <Slider className={"shopModule-slider-test"} min={20} defaultValue={20}*/}
                                    {/*                    marks={{20: "7dni", 60: "14dni", 100: "30dni"}} step={null}*/}
                                    {/*                    onChange={value => this.changeDays(value)}/>*/}
                                    {/*        </div>*/}
                                    {/*    </div> : null}*/}
                                    <InputPayment main={this}/>
                                </Col>
                                <DescriptionPayment description={this.props.value.description}/>
                            </Row>
                            <ButtonPayment main={this}/>
                        </Container>
                    </div>
                </Modal>
            </Col>
        );
    }
}

export default ShopItem;