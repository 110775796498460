import {Link} from "react-router-dom"
import React from "react";

const FooterItem = (property) => {
    return (

        <div className={"footer-item padding-bot-5 text-align-center"}>
            <Link to={property.footerItemLink}>
                <span className={"text-color-white"}>{property.footerText}</span>
            </Link>
        </div>

    );
};

export default FooterItem;