import React from "react";
import Line from "../../files/svg/test.svg";
import {Container, Row} from "react-bootstrap";

import ShopItem from "./object/ShopItem";

import VIP from "../../files/sklep/vip.svg";
import SVIP from "../../files/sklep/svip.svg";
import UNBAN from "../../files/sklep/unban.svg";
import UNMUTE from "../../files/sklep/unmute.svg";
import COINS5k from "../../files/sklep/coins_5k.svg";
import COINS10k from "../../files/sklep/coins_10k.svg";
import COINS25k from "../../files/sklep/coins_25k.svg";
import COINS50k from "../../files/sklep/coins_50k.svg";

import {store} from 'react-notifications-component';
import {Fade} from "react-reveal";

import { GoogleAnalytics } from "../../components/Google-Analytics";

class Shop extends React.Component {

    constructor(props) {
        super(props);

        /*TODO zmiana na rangi stałe*/

        // this.rang = [
        //     {
        //         name: "VIP",
        //         image: VIP,
        //         description: [
        //             "Zmniejszony cooldown na chat",
        //             "Latanie na lobby",
        //             "Dostep do niektorych skinow na incognito pod komenda /incognito",
        //             "Mozliwosc resetowania rankingu co 24h /resetujranking",
        //             "Zdobywanie Coins 1.5x razy szybciej",
        //             "Prefix VIP na chacie",
        //             "Ranga VIP na discordzie",
        //         ],
        //         offer: [
        //             {
        //                 id: 1,
        //                 days: 7,
        //                 price: [6.99, 7.00, 7.00, 9.99]
        //             },
        //             {
        //                 id: 2,
        //                 days: 14,
        //                 price: [13.99, 14.00, 14.00, 18.99]
        //             },
        //             {
        //                 id: 3,
        //                 days: 30,
        //                 price: [24.99, 25.00, 25.00, 32.99]
        //             }
        //         ]
        //     },
        //     {
        //         name: "SVIP",
        //         image: SVIP,
        //         description: [
        //             "Zmniejszony cooldown na chat",
        //             "Latanie na lobby",
        //             "Dostep do niektorych skinow na incognito pod komenda /incognito",
        //             "Mozliwosc resetowania rankingu co 12h /resetujranking",
        //             "Zdobywanie Coins 2x razy szybciej",
        //             "Prefix SVIP na chacie",
        //             "Ranga SVIP na discordzie",
        //         ],
        //         offer: [
        //             {
        //                 id: 4,
        //                 days: 7,
        //                 price: [11.99, 12.00, 12.00, 15.99]
        //             },
        //             {
        //                 id: 5,
        //                 days: 14,
        //                 price: [19.98, 20.00, 20.00, 24.99]
        //             },
        //             {
        //                 id: 6,
        //                 days: 30,
        //                 price: [29.99, 30.00, 30.00, 41.99]
        //             }
        //         ]
        //     }
        // ];
        this.rang = [
            {
                name: "VIP",
                image: VIP,
                description: [
                    "Zmniejszony cooldown na chat",
                    "Latanie na lobby",
                    "Dostep do niektorych skinow na incognito pod komenda /incognito",
                    "Mozliwosc resetowania rankingu co 24h /resetujranking",
                    "Niższe ceny w sklepie o 15%",
                    "Prefix VIP na chacie",
                    "Ranga VIP na discordzie",
                ],
                offer: [
                    {
                        id: 13,
                        price: [10.00, 10.00, 10.00, 15.99]
                    }
                ]
            },
            {
                name: "SVIP",
                image: SVIP,
                description: [
                    "Zmniejszony cooldown na chat",
                    "Latanie na lobby",
                    "Dostep do niektorych skinow na incognito pod komenda /incognito",
                    "Mozliwosc resetowania rankingu co 12h /resetujranking",
                    "Niższe ceny w sklepie o 30%",
                    "Prefix SVIP na chacie",
                    "Ranga SVIP na discordzie",
                ],
                offer: [
                    {
                        id: 14,
                        price: [20.00, 20.00, 20.00, 30.00]
                    }
                ]
            }
        ];


        this.other = [
            {
                name: "UnBan",
                image: UNBAN,
                description: [
                    "Dostajesz unbana na serwerze",
                    "",
                    "Przeskrobałeś na serwerze i chcesz odkupić swoje winy? Tutaj jest twoja druga szansa!",
                ],
                offer: {
                    id: 7,
                    days: 30,
                    price: [20.00, 20.00, 20.00, 30.00]
                }
            },
            {
                name: "UnMute",
                image: UNMUTE,
                description: [
                    "Dostajesz unmute na serwerze",
                    "",
                    "Przeskrobałeś na czacie i chcesz odkupić swoje winy? Tutaj jest twoja druga szansa!",
                ],
                offer: {
                    id: 8,
                    days: 30,
                    price: [10.00, 10.00, 10.00, 15.99]
                }
            }
        ];

        this.coins = [
            {
                name: "Coins 5k",
                image: COINS5k,
                description: [
                    "COINS to główna waluta na serwerze. Dzięki niej możesz kupić różne itemki i dodatki",
                ],
                offer: {
                    id: 9,
                    days: 30,
                    price: [25.00, 25.00, 25.00, 40.00]
                }
            },
            {
                name: "Coins 10k",
                image: COINS10k,
                description: [
                    "COINS to główna waluta na serwerze. Dzięki niej możesz kupić różne itemki i dodatki",
                ],
                offer: {
                    id: 10,
                    days: 30,
                    price: [50.00, 50.00, 50.00, 75.99]
                }
            },
            {
                name: "Coins 25k",
                image: COINS25k,
                description: [
                    "COINS to główna waluta na serwerze. Dzięki niej możesz kupić różne itemki i dodatki",
                ],
                offer: {
                    id: 11,
                    days: 30,
                    price: [100.00, 100.00, 100.00, 150.00]
                }
            }, {
                name: "Coins 50k",
                image: COINS50k,
                description: [
                    "COINS to główna waluta na serwerze. Dzięki niej możesz kupić różne itemki i dodatki",
                ],
                offer: {
                    id: 12,
                    days: 30,
                    price: [150.00, 150.00, 150.00, 225.00]
                }
            }
        ];
    }

    componentDidMount() {
        GoogleAnalytics.initGoogleAnalytics("shop");

        if (window.location.href.includes("#ok")) {
            store.addNotification({
                title: "Sklep",
                message: "Poprawnie dokonałeś zakupu w sklepie!   ❤\nDziękujemy!",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__bounceIn"],
                animationOut: ["animate__bounceOut"],
                dismiss: {
                    duration: 7500,
                    onScreen: true
                }
            });
            this.props.history.push('/sklep');
        } else if (window.location.href.includes("#fail")) {
            store.addNotification({
                title: "Sklep",
                message: "Wystąpił błąd podczas zakupu!",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__bounceIn"],
                animationOut: ["animate__bounceOut"],
                dismiss: {
                    duration: 7500,
                    onScreen: true
                }
            });
            this.props.history.push('/sklep');
        }
    }

    render() {
        return (
            <div className={"index-background bg-image img-mirror"}>
                <img className={"navbar-background-padding"} src={Line} alt={"valon tło"}/>
                <div className={"text-align padding-top-120 title-background"}>
                    <Fade bottom distance={"125px"} duration={950}>
                        <h1 className={"title-1"}>SKLEP</h1>
                    </Fade>
                    <Container>
                        <Fade bottom distance={"125px"} duration={950}>
                            <h3 className={"shopTitle"}>RANGI</h3>
                        </Fade>
                        <Row className={"justify-content-center"}>
                            {this.rang.map(value => {
                                return (<ShopItem key={value.name} value={value} isRang={true}/>);
                            })}
                        </Row>

                        <Fade bottom distance={"125px"} duration={950}>
                            <h3 className={"shopTitle"}>RÓŻNE</h3>
                        </Fade>
                        <Row className={"justify-content-center"}>
                            {this.other.map(value => {
                                return (<ShopItem key={value.name} value={value} isRang={false}/>);
                            })}
                        </Row>

                        <Fade bottom distance={"125px"} duration={950}>
                            <h3 className={"shopTitle"}>COINSY</h3>
                        </Fade>

                        {/*<Fade bottom distance={"125px"} duration={950}>*/}
                        {/*    <div>*/}
                        {/*        <h3 className={"shopError"}>Zakup coinsów został wyłączony podczas startu edycji :)</h3>*/}
                        {/*    </div>*/}
                        {/*</Fade>*/}

                        <Row className={"justify-content-center"}>
                            {this.coins.map(value => {
                                return (<ShopItem key={value.name} value={value} isRang={false}/>);
                            })}
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Shop;