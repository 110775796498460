import React, {useEffect} from "react";
import "../../style/css/index.css";

import Presentation from "./object/Presentation";
import Changelog from "./object/changelog/Changelog";

import Test from "../../files/svg/test.svg";

import { GoogleAnalytics } from "../../components/Google-Analytics";

const Home = () => {

    useEffect(() => {
        GoogleAnalytics.initGoogleAnalytics("home");
    });

    return (
        <div className={"index-background bg-image padding-top-80"}>
            <Presentation/>
            <img className={"padding-top-115"} src={Test} alt={"valon tło"}/>
            <Changelog/>
        </div>
    );
};

export default Home;