import React from "react";

const InputPayment = (property) => {
    return (
        <div>
            <input type={"text"} placeholder={"nick"} value={property.main.state.lastName}
                   className={"shopModule-input-text"} required={true}
                   onChange={event => property.main.setState({lastName: event.target.value})}/>

            {property.main.state.paymentType == 0 ?
                <input type={"email"} placeholder={"e-mail"} value={property.main.state.email}
                       className={"shopModule-input-text"} required={true}
                       onChange={event => property.main.setState({email: event.target.value})}/> :
                null}
        </div>
    );
};

export default InputPayment;