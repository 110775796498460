import React from "react";
import axios from "axios";

import ChangeBox from "./object/ChangeBox";

import Fade from "react-reveal"

const {url_api} = require("../../../../config");

class Changelog extends React.Component {
    state = {
        changelogs: []
    }

    componentDidMount() {
        this.fechUsers()
    }

    async fechUsers() {
        const request = await axios.get(url_api + "changelogs")
        const changelogs = request.data;

        this.setState({changelogs})
    }

    render() {
        return (
            <div className={"text-align padding-top-120 title-background"}>
                <Fade bottom distance={"125px"} duration={950}>
                    <h1 className={"title-1"}>CHANGELOG</h1>
                    <h2 className={"title-2"}>Najnowsze zmiany na serwerze</h2>
                </Fade>
                {this.state.changelogs.map(value => {
                    return (<ChangeBox key={value["version"]} value={value}/>);
                })}
            </div>
        );
    }
}

export default Changelog;