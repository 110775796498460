import React from "react";

import ShopPaymentButton from "./ShopPaymentButton";
import {ImCreditCard, ImMobile} from "react-icons/im";
import {RiNewspaperLine, RiPaypalLine} from "react-icons/ri";
import {Col} from "react-bootstrap";

const MethodPayment = (property) => {
    return (
        <Col md={4} sm={4} xs={4}>
            <h2 className={"shopModule-title"}>Metody płatności</h2>
            <ShopPaymentButton main={property.main} id={0} icon={<ImCreditCard/>}/>
            <ShopPaymentButton main={property.main} id={1} icon={<RiNewspaperLine/>}/>
            <ShopPaymentButton main={property.main} id={2} icon={<RiPaypalLine/>}/>
            <ShopPaymentButton main={property.main} id={3} icon={<ImMobile/>}/>
        </Col>
    );
};

export default MethodPayment;