import React from "react";
import Line from "../files/svg/test.svg";

import {IoBuildOutline} from "react-icons/io5"
import {GoogleAnalytics} from "../components/Google-Analytics";

class Toplist extends React.Component {

    componentDidMount() {
        GoogleAnalytics.initGoogleAnalytics("toplist");
    };

    render() {
        return (
            <div className={"index-background bg-image img-mirror"}>
                <img className={"navbar-background-padding"} src={Line} alt={"valon tło"}/>
                <div className={"text-align padding-top-120 title-background"}>
                    <h1 className={"title-1"}>TOPKI</h1>
                    <h2 className={"title-2"}>Najlepsi gracze na serwerze!</h2>
                    <span style={{fontSize: 350}}><IoBuildOutline/></span>
                </div>
            </div>
        );
    }
}

export default Toplist;