import React from "react";
import {Link} from "react-router-dom";
import "../../../style/css/index.css";
import "bootstrap/dist/css/bootstrap.css";
import {Col, Container, Navbar, Row} from "react-bootstrap";

const NavItemBrand = (property) => {

    return (
        <Navbar.Brand as={Link} to={property.href}>
            <Container>
                <Row className={"width-270"}>
                    {property.state.isHide ? null :
                        <Col md={"auto"} className={"navitem-logo2"}><img src={property.imageSRC}
                                                                          className={"navitem-logo"}
                                                                          alt={"valon logo"}/></Col>}
                    <Col md={"auto"} className={"margin-auto"}>
                        {property.state.isHide ? null :
                            <div><span className={"navitem-text2"}>{property.indexSubName}</span></div>}
                        <div>
                            <span className={"navitem-text"}>{property.indexName}</span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Navbar.Brand>
    );
};

export default NavItemBrand;