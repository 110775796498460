import React from "react";

import "../../style/css/index.css";

import {Col, Container, Row} from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.css"

import logoValon from "../../files/svg/valonLOGO.svg";
import logoBP from "../../files/svg/blazingpack.svg";

import {FaDiscord, FaTeamspeak} from "react-icons/fa";

import FooterTitle from "./object/FooterTitle";
import FooterItem from "./object/FooterItem";
import FooterImage from "./object/FooterImage";
import FooterIcon from "./object/FooterIcon";

import FooterCreators from "./object/FooterCreators";

import Fade from "react-reveal";

const Footer = () => {
    return (
        <div className={"footer"}>
            <Fade bottom distance={"20px"} duration={950}>
                <Container>
                    <Row>
                        <Col>
                            <FooterImage footerImageSRC={logoValon}/>
                        </Col>
                        <Col className={"margin-auto"}>
                            <FooterTitle footerTitleName={"KONTAKT"}/>
                            <div className={"text-align-center"}>
                                <FooterIcon footerIcon={<FaDiscord/>} footerLink={"https://discord.gg/EezPdHE49k"}/>
                                <FooterIcon footerIcon={<FaTeamspeak/>} footerLink={"ts3server://valon.pl"}/>
                            </div>
                        </Col>
                        <Col className={"margin-auto"}>
                            <div>
                                <FooterTitle footerTitleName={"PRZYDATNE LINKI"}/>
                            </div>

                            <div>
                                <FooterItem footerText={"Sklep"} footerItemLink={"/sklep"}/>
                                <FooterItem footerText={"Regulamin"} footerItemLink={"/regulamin"}/>
                                <FooterItem footerText={"Wyszukaj"} footerItemLink={"/"}/>
                            </div>
                        </Col>
                        <Col>
                            <FooterImage footerImageSRC={logoBP}/>
                        </Col>
                    </Row>
                </Container>
            </Fade>
            <Container>
                <Row>
                    <FooterCreators/>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;