import "../../../style/css/index.css"

const FooterImage = (property) => {
    return (
        <div className={"text-align-center"}>
            <img src={property.footerImageSRC} className={"footer-image"}/>
        </div>
    );
};

export default FooterImage;