import React from "react";
import {Col, Container, Row} from "react-bootstrap";

import {BsArrowClockwise} from "react-icons/bs";
import {IoAdd} from "react-icons/io5";
import {BiMinus} from "react-icons/bi";

const ChangeItem = (property) => {
    return (
        <div>
            <div className={"margin-bot-7 text-color-dark"}>
                {property.type == "add" ? <span>Dodano</span> : ""}
                {property.type == "remove" ? <span>Usunięto</span> : ""}
                {property.type == "change" ? <span>Zmieniono</span> : ""}
            </div>
            <Container>
                <Row className={"justify-content-center"}>
                    {property.array.map(element => {
                        return (
                            <Col md={"auto"}>
                                <div className={"changelogbox-" + property.type}>
                                    {property.type == "add" ?
                                        <span className={"changelogbox-add-icon"}><IoAdd/></span> : ""}
                                    {property.type == "remove" ?
                                        <span className={"changelogbox-remove-icon"}><BiMinus/></span> : ""}
                                    {property.type == "change" ?
                                        <span className={"changelogbox-change-icon"}><BsArrowClockwise/></span> : ""}

                                    <span>{element}</span>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
            <hr className={"text-color-gray"}/>
        </div>
    );
};

export default ChangeItem;