import React from "react";

import {Col, Container, Row} from "react-bootstrap";

import logoValon from "../../../files/svg/valonLOGO.svg";

import {Fade} from "react-reveal";

import CountUp from 'react-countup';

const Presentation = () => {
    return (
        <Container>
            <Row className={"text-align padding-top-120"}>
                <Col md={6} className={"margin-auto"}>
                    <Fade bottom duration={750} cascade>
                        <h1 className={"presentation-h1"}>VALON.PL</h1>
                        <h3 className={"presentation-h3"}>
                            Jedyny serwer MeetUp-UHC w Polsce
                        </h3>
                    </Fade>
                </Col>
                <Col md={6} className={"margin-auto"}>
                    <Fade bottom duration={650} delay={100} distance={"75px"}>
                        <img className={"presentation-image"} src={logoValon} alt={"Valon Logo"}/>
                    </Fade>
                </Col>
            </Row>
        </Container>
    );
};

export default Presentation;