import React from "react";

const FooterCreators = () => {
    return (
        <div className={"text-align-center background-color-black footer-creators"}>
            <span className={"text-color-white"}>© 2020-2021 - VALON.PL | Wszelkie prawa zastrzeżone</span>
        </div>
    );
};

export default FooterCreators;