const ShopPaymentButton = (property) => {
    return (
        <div onClick={() => property.main.changeButton(property.id)}
             className={"shopButton-container" + (property.id == property.main.state.paymentType ? "-active" : "")}>
            <div className={"margin-auto"}>
                <span>{property.icon} </span>
                <span>{property.main.getName(property.id)}</span>
            </div>
        </div>
    );
}

export default ShopPaymentButton;