import React from "react";
import {Col, Row} from "react-bootstrap";
import {Fade} from "react-reveal";

const ContainerPayment = (property) => {
    return (
        <Fade bottom cascade distance={"125px"} duration={950}>
            <Col md={"auto"} className={"shopItem-col"} onClick={property.main.openModal}>
                <Row className={"shopItem-container"}>
                    <Col md={6}>
                        <img src={property.image} height={100}/>
                    </Col>
                    <Col md={6} className={"margin-auto"}>
                        <div className={"shopItem-name"}>
                            <span>{property.name}</span>
                        </div>
                        <div className={"shopItem-price"}>
                            <span>od {property.price}PLN</span>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Fade>
    );
};

export default ContainerPayment;