import React from "react";
import {Route, Switch} from "react-router-dom";

import "./style/css/index.css"

import NavBar from "./components/navbar/NavBar";

import Home from "./pages/home/Home"
import Shop from "./pages/shop/Shop";
import Toplist from "./pages/Toplist";
import Rule from "./pages/rule/Rule";
import Footer from "./components/footer/Footer";
import Mod from "./pages/other/Mod";

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

class App extends React.Component {

    render() {
        return (
            <div>
                <ReactNotification/>
                <NavBar/>
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="/sklep" exact component={Shop}/>
                    <Route path="/ranking" exact component={Toplist}/>
                    <Route path="/regulamin" exact component={Rule}/>
                    <Route path="/rekrutacja" exact component={Mod}/>
                    <Route path="*" exact component={Shop}/>
                </Switch>
                <Footer/>

            </div>
        );
    }
}

export default App;




