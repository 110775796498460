import React, {useEffect} from "react";
import "../../style/css/index.css";

import Presentation from "../home/object/Presentation";
import {GoogleAnalytics} from "../../components/Google-Analytics";

const Mod = () => {

    useEffect(() => {
        GoogleAnalytics.initGoogleAnalytics("rekrutacja");
        window.location.href = "https://docs.google.com/forms/d/1Q_JD2lrnFp9MO4Tn8uTMs8BNQrwmXPc8KyEhScjDSWE";
    });

    return (
        <div className={"index-background bg-image padding-top-80"}>
            <Presentation/>
        </div>
    );
}

export default Mod;