import React from "react";
import {Col, Row} from "react-bootstrap";

const ButtonPayment = (property) => {
    return (
        <Row>
            <Col md={12} sm={12} xs={12}>
                <div className={"shopModule-info"}>
                    <h2>
                        <span>CENA:</span> {property.main.props.isRang ? property.main.props.value.offer[property.main.state.id].price[property.main.state.paymentType] : property.main.props.value.offer.price[property.main.state.paymentType]}ZŁ
                        (z VAT)</h2>
                    {/*TODO zmiana na rangi stałe*/}
                    {/*{property.main.props.isRang ? <h3>Ranga będzie aktywna*/}
                    {/*    przez <span>{property.main.props.value.offer[property.main.state.id].days}dni</span> od dnia*/}
                    {/*    zakupu*</h3> : null}*/}
                    {property.main.props.isRang ? <h3>Ranga będzie aktywna przez całą edycje!</h3> : null}
                </div>
                <div className={"shopModule-pay"}>
                    <input className={"shopModule-input-checkbox"} type={"checkbox"}
                           value={property.main.state.checkbox}
                           onChange={event => property.main.setState({checkbox: event.target.checked})}/>
                    <span className={"shopModule-input-checkbox-text"}>Akceptuje regulamin serwera</span>
                </div>
                <div
                    className={"shopModule-button" + ((property.main.state.paymentType == 0 ? ((property.main.state.email.length != 0 && property.main.state.lastName.length != 0 && property.main.state.checkbox) ? "-active" : "") : ((property.main.state.lastName.length != 0 && property.main.state.checkbox) ? "-active" : "")))}
                    onClick={() => property.main.createPayments()}>
                    <span>Zapłać</span>
                </div>
            </Col>
        </Row>
    );
};

export default ButtonPayment;