
const FooterIcon = (property) => {
    return (
        <a href={property.footerLink}>
            <span className={"footer-icon"}>
                {property.footerIcon}
            </span>
        </a>
    );
};

export default FooterIcon;