import React from "react";

import "../../style/css/index.css";

import NavItemText from "./object/NavItemText";
import NavItemBrand from "./object/NavItemBrand";

import logoValon from "../../files/svg/valonLOGO.svg";

import {FaHome, FaShoppingCart, FaTrophy} from "react-icons/fa";
import {CgSearchLoading} from "react-icons/cg";


import {Container, Nav, Navbar} from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.css"

class NavBar extends React.Component {
    state = {
        isTop: "",
        isHide: false,
    };

    componentDidMount() {
        document.addEventListener("scroll", () => {
            if (window.scrollY < 1) {
                this.setState({isTop: "scrollRemove"});
                setTimeout(() => {
                    this.setState({isHide: false});
                }, 175);
            } else {
                this.setState({isTop: "scroll"});
                setTimeout(() => {
                    this.setState({isHide: true});
                }, 175);
            }
        });
    }

    render() {
        return (
            <Navbar collapseOnSelect expand="md" fixed="top" className={this.state.isTop}>
                <Container>
                    <NavItemBrand href={"/"} indexName={"VALON.PL"} indexSubName={"MEETUP-UHC"} imageSRC={logoValon}
                                  state={this.state}/>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <NavItemText href={"/"} indexName={"STRONA GŁÓWNA"} icon={<FaHome/>}/>
                            <NavItemText href={"/ranking"} indexName={"RANKING"} icon={<FaTrophy/>}/>
                            <NavItemText href={"/sklep"} indexName={"SKLEP"} icon={<FaShoppingCart/>}
                                         customClass={"shoptest"}/>
                            <NavItemText href={"/"} indexName={"WYSZUKAJ"} icon={<CgSearchLoading/>}/>
                        </Nav>

                    </Navbar.Collapse>

                </Container>
            </Navbar>
        );
    }
}

export default NavBar;