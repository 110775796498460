import React from "react";
import {Link} from "react-router-dom";
import "../../../style/css/index.css";
import {Nav} from "react-bootstrap";

const NavItemText = (property) => {

    return (
        <div className={"navitem " + property.customClass}>
            <Nav.Link as={Link} to={property.href} eventKey={property.indexName} className={"navitem-center"}>
                {property.icon != null ? <span className={"nav-icon"}>{property.icon}</span> : null}
                {property.indexName != null ? <span className={"link"}>{property.indexName}</span> : null}
            </Nav.Link>
        </div>
    );
};

export default NavItemText;