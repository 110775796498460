import React from "react";
import {Col} from "react-bootstrap";

const DescriptionPayment = (property) => {
    return (
        <Col md={4} sm={4} xs={4}>
            <h2 className={"shopModule-title"}>Opis</h2>
            <div className={"shopModule-description"}>
                {property.description.map(value => {
                    return (<p key={value}>{value}</p>);
                })}
            </div>
        </Col>
    );
};

export default DescriptionPayment;