import React, {useEffect} from "react";
import Line from "../../files/svg/test.svg";
import {Col, Container, Row} from "react-bootstrap";

import RuleItem from "./object/RuleItem";
import RuleItemTitle from "./object/RuleItemTitle";
import RuleItem2 from "./object/RuleItem2";
import {GoogleAnalytics} from "../../components/Google-Analytics";

const Rule = () => {

    useEffect(() => {
        GoogleAnalytics.initGoogleAnalytics("rule");
    });

    return (
        <div className={"index-background bg-image img-mirror"}>
            <img className={"navbar-background-padding"} src={Line} alt={"valon tło"}/>
            <div className={"text-align padding-top-120 title-background"}>
                <h1 className={"title-1"}>REGULAMIN</h1>
                <h2 className={"title-2"}>Zasady panujące na serwerze</h2>
                <Container className={"padding-top-60"}>
                    <Row className="justify-content-md-center">
                        <Col md={"6"} sm={"12"}>
                            <div className={"rule-container"}>
                                <h1 className={"rule-index"}>REGULAMIN OGÓLNY</h1>
                                <div className={"padding-top-20"}>
                                    <RuleItemTitle text={"I. Serwer"}/>
                                    <RuleItem
                                        text={"1. Serwer valon.pl nie jest w żaden sposób powiązany z firmą Mojang AB."}/>
                                    <RuleItem
                                        text={"2. Każdy gracz, wchodząc na serwer bądź stronę automatycznie akceptuje regulamin."}/>
                                    <RuleItem text={"3. Regulamin serwera może ulec zmianie w każdej chwili."}/>
                                    <RuleItem text={"4. Właściciel zastrzega sobie prawo do zmiany regulaminu."}/>
                                    <RuleItem
                                        text={"5. Administracja serwera valon.pl ma prawo ukarać gracza za czyn nieuwzględniony w regulaminie, który negatywnie wpływa na opinie lub działanie serwera."}/>
                                    <RuleItem
                                        text={"6. Administracja serwera valon.pl zawsze ma racje."}/>
                                    <RuleItem
                                        text={"7. Wyzywanie Administratora serwera może wiązać się z banem/mutem"}/>
                                    <RuleItem
                                        text={"8. Po zakupie wybranej usługi serwis valon.pl automatycznie przyznaje ją klientowi."}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={"6"} sm={"12"}>
                            <div className={"rule-container"}>
                                <h1 className={"rule-index"}>REGULAMIN PŁATNOŚCI</h1>
                                <div className={"padding-top-20"}>
                                    <RuleItemTitle text={"I. Ogólny"}/>
                                    <RuleItem
                                        text={"1. Ceny w Sklepie zamieszczone przy danym Produkcie stanowią ceny brutto i określone są w złotych polskich (PLN)"}/>
                                    <RuleItem
                                        text={"2. Ceny usług mogą różnić się zależnie od wybranej formy płatności przy zakupie."}/>
                                    <RuleItem
                                        text={"3. Usługi sprzedaży oferowane w serwisie, są przedmiotami wirtualnymi, dostępnymi tylko w formie elektronicznej na serwerze valon.pl do gry Minecraft."}/>
                                    <RuleItem
                                        text={"4. Wszelkie usługi oraz przedmioty, są własnością serwera valon.pl , a klientowi (graczowi) przypisuje się do nich dostęp."}/>
                                    <RuleItem text={"6. Dostępnymi formami płatności w serwisie są: PaySafeCard, PayPal, Przelew oraz Direct Billing, Karta"}/>
                                    <RuleItem text={"7. Płatności obsługiwane są przez firmy Paybylink NIP - 5252815885 (Paysafecard), Paypal NIP - 5252406419 (Paypal), mElements NIP - 5223047892 (Przelew), Digital Polska Virgo NIP - 5272317216 (Direct Billing), Blue Media S.A. NIP - 585-13-51-185 (Karta)"}/>
                                    <RuleItem text={"8. Sprzedawane usługi posiadają postać wirtualną"}/>
                                    <RuleItem text={"9. Aktywacja/Dostawa usługi może potrwać do 10 minut"}/>
                                    <RuleItem text={"10. W przypadku upadku serwera bądź tzw. \"Nowej edycji\" wszelkie wirtualne przedmioty, dostęp do wszystkich usług jednorazowych zostaje zablokowany."}/>
                                    <RuleItemTitle text={"II. Odstąpienie od umowy"}/>
                                    <RuleItem
                                        text={"1. Klient może odstąpić od umowy bez podania przyczyn, składając stosowne oświadczenie na piśmie w terminie 14 (słownie: czternaście) dni od dnia zakupu na adres korespondencyjny Administratora."}/>
                                    <RuleItem
                                        text={"2. Prawo odstąpienia od umowy nie przysługuje Klientowi w odniesieniu do umów o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgoda Klienta przed upływem terminu do odstąpienia od umowy."}/>
                                    <RuleItem
                                        text={"3. Prawo odstąpienia od umowy nie przysługuje Klientowi w odniesieniu do umów o świadczenie usług, jeżeli Sprzedawca wykonał w pełni usługę za wyraźna zgoda Klienta."}/>
                                    <RuleItemTitle text={"III. Reklamacje"}/>
                                    <RuleItem
                                        text={"1. Klient może zgłosić Sprzedawcy reklamację w związku z realizacją umowy sprzedaży na adres korespondencyjny Administratora. Sprzedawca niezwłocznie, lecz nie później niż w terminie 14 (słownie: czternaście) dni rozpatruje reklamacje i udziela odpowiedzi na adres podany w danym zgłoszeniu."}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={"6"} sm={"12"}>
                            <div className={"rule-container"}>
                                <h1 className={"rule-index"}>POLITYKA PRYWATNOŚCI</h1>
                                <div className={"padding-top-20"}>
                                    <RuleItemTitle text={"I. Informacje ogólne"}/>
                                    <RuleItem
                                        text={"1. Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych przekazanych przez Użytkowników."}/>
                                    <RuleItem
                                        text={"2. Administratorem danych osobowych zawartych w serwisie jest Bartłomiej Szulc z siedzibą w Lublińcu NIP: 575-19-02-987 REGON: 388672440"}/>
                                    <RuleItem
                                        text={"3. Dane osobowe pozostawione w serwisie nie zostaną sprzedane ani udostępnione osobom trzecim, zgodnie z przepisami Ustawy o ochronie danych osobowych.\n"}/>
                                    <RuleItem
                                        text={"4. W celu otrzymania dostępu do Serwisu/zamówienia użytkownik ma możliwość skorzystać z Usługi Aktywacyjnej Justpay zgodnie z zasadami umieszczonymi na stronie WWW Serwisu. Użytkownik korzystający z usługi dostępowej świadczonej przez Polkomtel Sp. z o.o., w ramach której uzyskał kod, nie będzie ponosił opłat za skorzystanie z valon.pl"}/>
                                    <RuleItemTitle text={"II. Zasady przetwarzania danych osobowych"}/>
                                    <RuleItem text={"1. Administrator podejmuje techniczne i organizacyjne środki służące ochronie danych osobowych przed bezprawnym, w szczególności nieautoryzowanym dostępem lub wykorzystaniem ich, jak również przed przypadkowym ich zniszczeniem, utratą lub naruszeniem integralności."}/>
                                    <RuleItem text={"2. Dane osobowe, które Użytkownik przekazuje Administratorowi w związku z korzystaniem serwisów internetowych i innych usług Administratora i powiązanych z nimi usług, są szyfrowane i chronione za pomocą certyfikatu SSL."}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={"6"} sm={"12"}>
                            <div className={"rule-container"}>
                                <h1 className={"rule-index"}>POLITYKA PLIKÓW COOKIES</h1>
                                <div className={"padding-top-20"}>
                                    <RuleItem text={"1. Poprzez piki “cookies” należy rozumieć dane informatyczne przechowywane w urządzeniach końcowych użytkowników, przeznaczone do korzystania ze stron internetowych. W szczególności są to pliki tekstowe, zawierające nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer."}/>
                                    <RuleItem text={"2. Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych w plikach cookies."}/>
                                    <RuleItem text={"3. Pliki cookies przeznaczone są do korzystania ze stron serwisu. Operator wykorzystuje te pliki do tworzenia anonimowych statystyk z wyłączeniem możliwości identyfikacji użytkownika."}/>
                                    <RuleItem text={"4. Pliki cookies wykorzystywane przez partnerów operatora strony internetowej, w tym w szczególności użytkowników strony internetowej, podlegają ich własnej polityce prywatności.or wykorzystuje te pliki do: a) możliwości logowania i utrzymywania sesji użytkownika na każdej kolejnej stronie serwisu b) dopasowania zawartości strony internetowej do indywidualnych preferencji użytkownika, przede wszystkim pliki te rozpoznają jego urządzenie, aby zgodnie z jego preferencjami wyświetlić stronę"}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={"6"} sm={"12"}>
                            <div className={"rule-container"}>
                                <h1 className={"rule-index"}>PRZEDSIĘBIORCA</h1>
                                <div className={"padding-top-20"}>
                                    <RuleItem2 text={"Bartłomiej Szulc"}/>
                                    <RuleItem2 text={"ul. Stalmacha 15/4"}/>
                                    <RuleItem2 text={"42-700 Lubliniec"}/>
                                    <RuleItem2 text={"NIP: 575-19-02-987"}/>
                                    <RuleItem2 text={"REGON: 388672440"}/>
                                    <RuleItem2 text={"E-Mail: kontakt@valon.pl"}/>
                                    <RuleItem2 text={"Telefon: +48 578-897-755"}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Rule;